import React, { useEffect, useState } from "react";
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import testi3 from '../../assets/images/testi-3.jpg';
import testi4 from '../../assets/images/testi-4.jpg';
import testicon from '../../assets/images/testi-icon.png';
import anne from '../../assets/images/Anne.jpg';
import kamal from '../../assets/images/kamal.jpg';
import ross from '../../assets/images/Ross.jpg';

const TestimonialSection = () => {
  SwiperCore.use([Autoplay, Pagination]);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <div className="section techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding-02">
        <div className="container">
          <div className="testimonial-wrap-02">
            <div className="section-title text-center">
              <h3 data-aos={isWideScreen ? "fade-left" : "undefined"} className="sub-title">Testimonial</h3>
              <h2 data-aos={isWideScreen ? "fade-right": "undefined"} className="title">100+ satisfied clients worldwide</h2>
            </div>
            <div className="testimonial-content-wrap-02">
              <div className="swiper-container testimonial-02-active">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={40}
                    slidesPerView={1}
                    breakpoints={{
                      // When screen width is >= 640px (tablet and above), show 3 slides
                      640: {
                        slidesPerView: 2,
                      },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    loop={true} 
                    autoplay={{
                      delay: 3000, // 3000 ms (3 seconds)
                      disableOnInteraction: false, // Keeps autoplay working even after user interaction
                    }}
                    pagination={{ clickable: true }}
                  >
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={ross} alt="Ross.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Exceptional service that consistently exceeds our expectations. The team is knowledgeable, responsive, and a true partner in our success.</p>
                            <span className="name">Ross Taylor</span>
                            <span className="designation"> / Designer, Templatefirm</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={testi4} alt="testi-4.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Outstanding IT support with prompt solutions and a high level of professionalism. Their innovative approach has significantly improved our operations.</p>
                            <span className="name">Roman</span>
                            <span className="designation"> / Vice president, Blogsenate</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={anne} alt="Anne.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>A reliable and efficient IT service provider. Their expertise and dedication have been invaluable to our business.</p>
                            <span className="name">Anne marie</span>
                            <span className="designation"> / CEO, Weddingmoments</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={kamal} alt="kamal.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Remarkable customer service and technical proficiency. They always go the extra mile to ensure our needs are met.</p>
                            <span className="name">Kamal Khanna</span>
                            <span className="designation"> / Manager, Historical</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={testi3} alt="testi-3.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Top-notch IT services delivered with excellence and attention to detail. They transform challenges into seamless solutions, enhancing our productivity.</p>
                            <span className="name">Anant Verma</span>
                            <span className="designation"> / CEO, Templatefirm</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                  </Swiper>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialSection;
