import React, { useState, useEffect, useRef } from "react";
import NumberCounter from 'number-counter';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import brand1 from '../assets/images/brand/brand-1.png';
import brand2 from '../assets/images/brand/brand-2.png';
import brand3 from '../assets/images/brand/brand-3.png';
import brand4 from '../assets/images/brand/brand-4.png';
import brand5 from '../assets/images/brand/brand-5.png';
import pageBanner from '../assets/images/bg/page-banner.jpg';
import shape2 from '../assets/images/shape/about-shape2.png';
import about3 from '../assets/images/about-3.jpg';
import about4 from '../assets/images/about-4.jpg';
import counterbg2 from '../assets/images/bg/counter-bg2.jpg';
import choosebg3 from '../assets/images/bg/choose-us-bg3.jpg';
import team1 from '../assets/images/team/team-1.jpg';
import team2 from '../assets/images/team/team-2.jpg';
import team3 from '../assets/images/team/team-3.jpg';
import team4 from '../assets/images/team/team-4.jpg';
import team5 from '../assets/images/team/team-5.jpg';
import team6 from '../assets/images/team/team-6.jpg';
import serIcon9 from '../assets/images/ser-icon9.png';
import signature from '../assets/images/sign.png';
import testi3 from '../assets/images/testi-3.jpg';
import testi4 from "../assets/images/testi-4.jpg";
import testicon from '../assets/images/testi-icon.png';
import ctaIcon2 from '../assets/images/cta-icon2.png';
import ctaBg from '../assets/images/bg/cta-bg.jpg';
import counter1 from '../assets/images/counter-1.png';
import counter2 from '../assets/images/counter-2.png';
import counter3 from '../assets/images/counter-3.png';
import serIcon10 from '../assets/images/ser-icon10.png';
import serIcon12 from '../assets/images/ser-icon12.png';
import serIcon21 from '../assets/images/ser-icon21.png';
import serIcon22 from '../assets/images/ser-icon22.png';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>Bilvaleaf: Web Design, Digital Marketing, Graphics & BPO/KPO</title>
        <meta name="description" content="Providing Web Development, web portals, digital marketing, and call center services." />
        <link rel="canonical" href="https://bilvaleaf.com/about" />
      </Helmet>
      {/* Page Banner Start */}
      <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
        <div className="shape-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
            <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
          </svg>
        </div>
        <div className="shape-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
            <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
          </svg>
        </div>
        <div className="container">
          <div className="page-banner-wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-banner text-center">
                  <h2 className="title">Bilvaleaf Private Limited</h2>
                  <ul className="breadcrumb justify-content-center">
                    {/* <li className="breadcrumb-item"><a href="/">Home</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">Bilvaleaf Private Limited is a dynamic company offering comprehensive solutions in Web Development, web portals, <br />digital marketing, and call center services.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Banner End */}

      {/* About Start */}
      <div className="section techwix-about-section-07 section-padding">
        <div className="shape-1"></div>
        <div className="container">
          <div className="about-wrap">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-img-wrap">
                  <img className="shape-1" src={`${shape2}`} alt="about-shape2.png" />
                  <div className="about-img">
                    <img src={`${about3}`} alt="about-3.jpg" />
                  </div>
                  <div className="about-img about-img-2">
                    <img src={`${about4}`} alt="about-4.jpg" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content-wrap">
                  <div className="section-title">
                    <h3 className="sub-title">Who we are</h3>
                    <h2 className="title">Delivering innovative and sustainable technology solutions.</h2>
                  </div>
                  <p className="text">With a talented team of designers, developers, and digital marketing experts, we specialize in creating stunning websites, executing targeted digital marketing campaigns, and providing top-notch customer support through our call center services. </p>
                  <div className="about-list-03">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="about-list-item-03">
                          <h3 className="title">Our Mission</h3>
                          <p>The mission of BilvaLeaf Private Limited is to redefine industry standards through innovative solutions, unwavering integrity, and a commitment to excellence.</p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="about-list-item-03">
                          <h3 className="title">Our Value</h3>
                          <p>At BilvaLeaf Private Limited, innovation, integrity, and excellence aren't just words – they're the driving force behind everything we do.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Counter Start */}
      <div className="section techwix-counter-section-03 techwix-counter-section-04">
        <div className="container">
          <div className="counter-wrap" style={{ backgroundImage: `url(${counterbg2})` }}>
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="single-counter-02 text-center">
                  <span><NumberCounter start={0} end={100} delay={5} postFix="+" /></span>
                  <p>HAPPY CLIENTS</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-counter-02 text-center">
                  <span><NumberCounter start={0} end={120} delay={5} postFix="+" /></span>
                  <p>FINISHED PROJECTS</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-counter-02 text-center">
                  <span><NumberCounter start={0} end={75} delay={5} postFix="+" /></span>
                  <p>SKILLED EXPERTS</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-counter-02 text-center">
                  <span><NumberCounter start={0} end={5000} delay={5} postFix="+" /></span>
                  <p>MEDIA POSTS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Counter End */}

      {/* Choose Us Start */}
      <div className="section techwix-choose-us-section section-padding" style={{ backgroundImage: `url(${choosebg3})` }}>
        <div className="container">
          <div className="choose-us-wrap">
            <div className="section-title text-center">
              <h3 className="sub-title">REASON TO CHOOSE US</h3>
              <h2 className="title">We provide truly prominent IT solutions.</h2>
            </div>
            <div className="choose-us-content-wrap">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Professional Team</a></h3>
                      <p>Our highly professional team members exhibit strong expertise, clear communication, and reliable accountability.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Innovation</a></h3>
                      <p>Innovation as a core value drives a company to continuously seek new ideas, improve processes, and develop cutting-edge products.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Excellence</a></h3>
                      <p>Excellence as a company value signifies a commitment to the highest standards in all aspects of business.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Customer First Approach</a></h3>
                      <p>We prioritize customer satisfaction, ensuring personalized and attentive service.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Proven Track Record</a></h3>
                      <p>A history of successful projects and satisfied clients underscores our reliability and effectiveness.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Comprehensive Services</a></h3>
                      <p>Bilvaleaf provides a wide range of services, making us a one-stop solution for your business.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Competitive Pricing</a></h3>
                      <p>We offer high-value services at competitive prices, ensuring you get the best return on your investment.</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="choose-us-item-02">
                    <div className="choose-us-img">
                      <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                    </div>
                    <div className="choose-us-content">
                      <h3 className="title"><a href="#">Continuous Improvement</a></h3>
                      <p>We continuously evolve and improve our processes to stay ahead in the industry.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Choose Us End --> */}

      {/* Skill Start */}
      <div className="section techwix-skill-section-02 techwix-skill-section-03 section-padding">
        <div className="container">
          <div className="skill-wrap">
            <div className="row">
              <div className="col-lg-6">
                <div className="skill-left">
                  <div className="section-title">
                    <h2 className="title">Crafting Digital Excellence with a Legacy of Innovation and Client Success.</h2>
                  </div>
                  <div className="about-list">
                    <ul>
                      <li>
                        <span className="about-icon"><i className="fas fa-check"></i></span>
                        <span className="about-text">Empowering businesses through strategic IT prowess and visionary solutions.</span>
                      </li>
                      <li>
                        <span className="about-icon"><i className="fas fa-check"></i></span>
                        <span className="about-text"> Accelerating growth with agile IT strategies and transformative innovations. </span>
                      </li>
                      <li>
                        <span className="about-icon"><i className="fas fa-check"></i></span>
                        <span className="about-text">Pioneering digital excellence with tailored IT solutions for sustained success.</span>
                      </li>
                    </ul>
                  </div>
                  <div className="about-author-info-wrap">
                    <div className="about-author">
                      {/* <img src={signature} alt="signature.jpeg" /> */}
                      <h3 className="name">Ramanuj Barai</h3>
                      <span className="designation">CEO, Director</span>
                    </div>
                    <div className="about-info">
                      <p>Drop to Ask any question</p>
                      <Link to="/contact">Contact</Link>
                      {/* <h3 className="number">0123-456-7890</h3> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="skill-right">
                  <p className="text">"Bilvaleaf Private Limited" has established itself as a leading provider of comprehensive digital solutions over the years. Specializing in Web Development, web portals, graphic designing, BPO-KPO services, and digital marketing, our company excels in delivering tailored solutions that cater to diverse business needs. With a proven track record of excellence, we combine creativity, technical expertise, and industry insight to create impactful digital experiences for our clients.</p>
                  <div className="counter-bar"><div className="skill-item"><span className="title">Web Development</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{ width: "80%" }}><span className="skill-percent"><span className="counter">80</span>%</span></div></div></div></div><div className="skill-item"><span className="title">Digital Marketing</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{ width: "95%" }}><span className="skill-percent"><span className="counter">95</span>%</span></div></div></div></div><div className="skill-item"><span className="title">Visual Designing</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{ width: "80%" }}><span className="skill-percent"><span className="counter">80</span>%</span></div></div></div></div><div className="skill-item"><span className="title">BPO/KPO</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{ width: "90%" }}><span className="skill-percent"><span className="counter">90</span>%</span></div></div></div></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Skill End */}

      {/* Team Start */}
      {/* <div className="section techwix-team-section techwix-team-section-02 section-padding" style={{ backgroundImage: `url(${choosebg3});` }}>
        <div className="container">
          <div className="team-wrap">
            <div className="section-title text-center">
              <h3 className="sub-title">With our expert team</h3>
              <h2 className="title white">Excellence is not a goal but a standard</h2>
            </div>
            <div className="team-content-wrap">
              <div className="swiper-container team-active">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <Swiper
                      spaceBetween={5}
                      slidesPerView={1}
                      breakpoints={{
                        640: {
                          slidesPerView: 4,
                        },
                      }}
                      loop={true}
                      autoplay={{
                        delay: 3000, 
                        disableOnInteraction: false, 
                      }}
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                      pagination={{ clickable: true }}
                    >
                      <SwiperSlide>
                        <div data-aos="fade-left" className="single-team">
                          <div className="team-img">
                            <Link to="/team">
                              <img src={team1} alt="team-1.jpg" />
                            </Link>
                          </div>
                          <div className="team-content">
                            <h3 className="name">
                              <Link to="/team">
                                Pradeep <br /> Kashyap
                              </Link>
                            </h3>
                            <span className="designation">Co-Founder, Designing Lead</span>
                            <div className="team-social">
                              <ul className="social">
                                <li>
                                  <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/bilvaleaf">
                                    <i className="fab fa-twitter" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/bilvaleaf/">
                                    <i className="fab fa-instagram" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/">
                                    <i className="fab fa-linkedin-in" target="_blank"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div data-aos="fade-left" className="single-team">
                          <div className="team-img">
                            <Link to="/team">
                              <img src={team2} alt="team-2.jpg" />
                            </Link>
                          </div>
                          <div className="team-content">
                            <h3 className="name">
                              <Link to="/team">
                                Sandeep <br /> Kumar
                              </Link>
                            </h3>
                            <span className="designation">Co-Founder, Digital Marketing Head</span>
                            <div className="team-social">
                              <ul className="social">
                                <li>
                                  <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/bilvaleaf">
                                    <i className="fab fa-twitter" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/bilvaleaf/">
                                    <i className="fab fa-instagram" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/">
                                    <i className="fab fa-linkedin-in" target="_blank"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div data-aos="fade-left" className="single-team">
                          <div className="team-img">
                            <Link to="/team">
                              <img src={team3} alt="team-3.jpg" />
                            </Link>
                          </div>
                          <div className="team-content">
                            <h3 className="name">
                              <Link to="/team">
                                Aashish <br /> Barai
                              </Link>
                            </h3>
                            <span className="designation">Co-Founder, Marketing & HR Head</span>
                            <div className="team-social">
                              <ul className="social">
                                <li>
                                  <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/bilvaleaf">
                                    <i className="fab fa-twitter" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/bilvaleaf/">
                                    <i className="fab fa-instagram" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/">
                                    <i className="fab fa-linkedin-in" target="_blank"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div data-aos="fade-left" className="single-team">
                          <div className="team-img">
                            <Link to="/team">
                              <img src={team4} alt="team-4.jpg" />
                            </Link>
                          </div>
                          <div className="team-content">
                            <h3 className="name">
                              <Link to="/team">
                                Chandan  <br /> Sharma
                              </Link>
                            </h3>
                            <span className="designation">Co-Founder, IT & Development Head</span>
                            <div className="team-social">
                              <ul className="social">
                                <li>
                                  <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/bilvaleaf">
                                    <i className="fab fa-twitter" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/bilvaleaf/">
                                    <i className="fab fa-instagram" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/">
                                    <i className="fab fa-linkedin-in" target="_blank"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div data-aos="fade-left" className="single-team">
                          <div className="team-img">
                            <Link to="/team">
                              <img src={team5} alt="team-5.jpg" />
                            </Link>
                          </div>
                          <div className="team-content">
                            <h3 className="name">
                              <Link to="/team">
                                Ramanuj <br /> Barai
                              </Link>
                            </h3>
                            <span className="designation">Co-Founder, CEO, Director</span>
                            <div className="team-social">
                              <ul className="social">
                                <li>
                                  <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/bilvaleaf">
                                    <i className="fab fa-twitter" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/bilvaleaf/">
                                    <i className="fab fa-instagram" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/">
                                    <i className="fab fa-linkedin-in" target="_blank"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div data-aos="fade-left" className="single-team">
                          <div className="team-img">
                            <Link to="/team">
                              <img src={team6} alt="team-6.jpg" />
                            </Link>
                          </div>
                          <div className="team-content">
                            <h3 className="name">
                              <Link to="/team">
                                Sakshi <br /> Chaudhary
                              </Link>
                            </h3>
                            <span className="designation">HR & Marketing Head</span>
                            <div className="team-social">
                              <ul className="social">
                                <li>
                                  <Link to="https://www.facebook.com/profile.php?id=61557420182345" target="_blank">
                                    <i className="fab fa-facebook-f"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://twitter.com/bilvaleaf">
                                    <i className="fab fa-twitter" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.instagram.com/bilvaleaf/">
                                    <i className="fab fa-instagram" target="_blank"></i>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/">
                                    <i className="fab fa-linkedin-in" target="_blank"></i>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Team End */}

      {/* Testimonial Start */}
      {/* <div className="section techwix-testimonial-section-02 techwix-testimonial-section-03 section-padding-02">
        <div className="container">
          <div className="testimonial-wrap-02">
            <div className="section-title text-center">
              <h3 className="sub-title">Testimonial</h3>
              <h2 className="title">20k+ satisfied clients worldwide</h2>
            </div>
            <div className="testimonial-content-wrap-02">
              <div className="swiper-container testimonial-02-active">
                <div className="swiper-wrapper">
                  <Swiper
                    spaceBetween={40}
                    slidesPerView={1}
                    breakpoints={{
                      // When screen width is >= 640px (tablet and above), show 3 slides
                      640: {
                        slidesPerView: 2,
                      },
                    }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    loop={true}
                    pagination={{ clickable: true }}
                  >
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={testi3} alt="testi-3.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                            <span className="name">Mike Holder </span>
                            <span className="designation"> / CEO, Harlond inc</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={testi4} alt="testi-4.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                            <span className="name">Mike Fermalin </span>
                            <span className="designation"> / CEO, Harlond inc</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                    <div className="swiper-slide">
                      <SwiperSlide>
                        <div className="single-testimonial-02">
                          <div className="testimonial-thumb">
                            <img src={testi3} alt="testi-3.jpg" />
                          </div>
                          <div className="testimonial-content">
                            <img src={testicon} alt="testi-icon.png" />
                            <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                            <span className="name">Mike Holder </span>
                            <span className="designation"> / CEO, Harlond inc</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>
                  </Swiper>
                </div>
                <div className="swiper-pagination">{SwiperCore.use([Pagination])}</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Testimonial End  --> */}

      {/* Cta Start */}
      <div className="section techwix-cta-section-02 section-padding-02">
        <div className="container">
          <div className="cta-wrap" style={{ backgroundImage: `url(${ctaBg})` }}>
            <div className="row align-items-center">
              <div className="col-xl-9 col-lg-8">
                <div className="cta-content">
                  <div className="cta-icon">
                    <img src={ctaIcon2} alt="cta-icon2.png" />
                  </div>
                  <p>We’re Delivering the best customer Experience</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="cta-btn">
                  <Link className="btn btn-class" to="/contact">+919816333105</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cta End */}
    </>
  )
}

export default AboutUs;